import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Thankyou from './pages/Thankyou'

const App = () => {

  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/thankyou" element={<Thankyou />} />
    </Routes>
  </BrowserRouter>
  )
}

export default App
