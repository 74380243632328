import * as React from 'react'
import {
  Box,
  Button,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import VenezuelaFlag from '../../assets/bandera venezuela.png'
import { useForm } from 'react-hook-form'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const grayTheme = createTheme({
  palette: {
    primary: {
      main: '#7070708D',
    },
  },
});

const whiteTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});

const RegisterForm = (props) => {
  const { handleSubmit, register, formState: {errors} } =  useForm()
  const {
    onSubmitLogin,
    handleChange,
    handleChangeDate
  } = props

  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() !== 6;
  }

  const onErrors = errors => console.error(errors)

  return (
    <ThemeProvider theme={grayTheme}>
      <form onSubmit={handleSubmit(onSubmitLogin, onErrors)}>
      <Box
        columnSpacing={{ lg: 1}}
        sx={{
          marginTop: '80px'
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            flexDirection: 'column'
          }}
        >
          <Grid
            item
            margin='0 auto'
            sx={{
              width: {xs: '100%'},
            }}
          >
            <TextField
              {...register('fullname', {
                required: 'Nombre completo requerido.',
                pattern: /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/,
              }
                
              )}
              sx={{
                width: '100%',
                background: '#F5F6F9',
              }}
              name='fullname'
              value={props.fullname}
              onChange={handleChange('fullname')}
              placeholder='Nombre completo'
              autoComplete='off'
              type='text'
              // eslint-disable-next-line
              helperText={errors.fullname?.message || errors.fullname?.type === 'pattern' && 'Ej: María Mijares'}
              error={Boolean(errors.fullname)}
            />
          </Grid>
          <Grid
            item
            margin='0 auto'
            sx={{
              width: {xs: '100%'},
            }}
          >
            <TextField
              {...register('email_main', {
                required: 'El Correo es requerido.',
                maxLength: 50,
                pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
              })}
              sx={{
                width: '100%',
                background: '#F5F6F9',
              }}
              name='email_main'
              value={props.email_main}
              onChange={handleChange('email_main')}
              placeholder='Email'
              autoComplete='off'
              type='email'
              
              helperText={
                errors.email_main?.message ||
                errors.email_main?.type === 'maxLength' &&
                'Correo inválido, muy largo' ||
                errors.email_main?.type === 'pattern' &&
                'Correo electrónico inválido. Ejemplo: correo@gmail.com'
              }
              error={Boolean(errors.email_main)}
            />
          </Grid>
          <Grid
            item
            margin='0 auto'
            sx={{
              width: {xs: '100%'},
            }}
          >
            <TextField
              {...register('phone_main', {
                required: 'Teléfono requerido.',
                pattern: /^([0-9()+ -.]{8,14})$/,
              }
              )}
              sx={{
                width: '100%',
                background: '#F5F6F9',
              }}
              name='phone_main'
              value={props.phone_main}
              onChange={handleChange('phone_main')}
              placeholder='Teléfono'
              label='Teléfono'
              autoComplete='off'
              type='text'
              // eslint-disable-next-line
              helperText={ errors.phone_main?.type === 'pattern' &&
                'Tlf inválido, ejemplo: +584148526396'
              }
              error={Boolean(errors.phone_main)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={VenezuelaFlag} width='30px' height='auto' alt='Venezuela'/>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid
            item
            margin='0 auto'
            color='#FF0000'
            sx={{
              width: {xs: '100%'},
            }}
          >
            <Box className='font-family-Airwaves' fontSize='20px' fontStyle='italic' width='100%'>
              ¿QUE CURSO TE INTERESA?
            </Box>
          </Grid>
          <Grid
            item
            margin='0 auto'
            color='#000'
            sx={{
              width: {xs: '100%'},
            }}
          >
            <FormControl sx={{ maxWidth: '100%', width: '100%' }}>
            <InputLabel id="course">Seleccione Curso</InputLabel>
            <Select
              {...register('course', {
                required: 'campo inválido',
              })}
              name='course'
              labelId='course'
              id='course'
              value={props.course}
              label='Seleccione Curso'
              defaultValue=''
              onChange={handleChange('course')}
              sx={{
                backgroundColor: '#F5F6F9',
              }}
            >
              <MenuItem disabled>Seleccione el curso</MenuItem>
              <MenuItem value={7}>PAMI Básico (8 Horas)</MenuItem>
            </Select>
            {errors.course?.type === 'required' &&
              <Box
                sx={{
                  color: '#e32',
                  fontSize: '12px',
                  display: 'flex',
                  flexDirection: 'flex-Start',
                  paddingLeft: '15px',
                  backgroundColor: '#f5f6f9',
                  paddingBottom: '5px',
                  paddingTop: '5px'
                }}
              >
                Curso requerido  
              </Box>
            }
            </FormControl>
          </Grid>
          <Box color='#e32726' fontSize={12}>
            {props.course === false && 'seleccione un campo'}
          </Box>
          <Grid
            item
            margin='0 auto'
            sx={{
              width: {xs: '100%'},
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name='available_date'
                ampm={false}
                label='Seleccionar fecha disponible'
                value={props?.available_date}
                onChange={handleChangeDate('available_date')}
                renderInput={(params) => 
                  <ThemeProvider theme={whiteTheme}>
                  <TextField
                    {...params}
                    fullWidth
                    sx={{
                      '.css-9f9zi3-MuiInputBase-root-MuiOutlinedInput-root': {
                        border: 'solid 1px #fff'
                      }, border:'none',
                      width: {
                        lg: '100%',
                        md: '100%',
                        sm: '100%',
                      }, '.css-14fjqu0-MuiFormLabel-root-MuiInputLabel-root': {
                        backgroundColor: '#fff',
                        paddingLeft: '5px',
                        paddingRight: '8px',
                        color: '#b0b0b0',
                      } }}
                    />
                    </ThemeProvider>
                }
                minDate='Sat Apr 29 2023 00:00:00 GMT-0400 (hora de Venezuela)'
                maxDate='Sat Apr 29 2023 00:00:00 GMT-0400 (hora de Venezuela)'
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      marginTop={2}
      flexDirection='column'
    >
      <Button
        variant='contained'
        color='error'
        type='submit'
        sx={{
          minWidth: '297.82px',
          height: '51.71px',
          bgcolor: '#f40103'
        }}
      >
        <Box className='font-family-Airwaves' fontSize='20px' fontStyle='italic' width='100%'>
          ENVIAR MIS DATOS
        </Box>
      </Button>
      <Grid item>
        <Box color='#e32726' fontSize={12} paddingTop={3}>
          {errors.terms && 'Acepte los terminos y condiciones'}
        </Box>
      </Grid>
      <Box
        className='font-family-AvenirNextCondensed'
        size='15px'
        color='#f53e31'
        mt={2}
        mb={2}
        sx={{
          fontWeight: 'bold'
        }}
      >
        Tus datos son 100% confidenciales
      </Box>
    </Box>
    </form>
  </ThemeProvider>
  )
}

export default RegisterForm
