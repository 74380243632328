
import Api from '../services/afiliationApi'

const json = {'Content-Type': 'application/json'}
//const formData = {'Content-Type': 'multipart/form-data'}

const saveRegisterForm = (data) => Api(json).post('/v1/event/fundation', data)
//eslint-disable-next-line
export default {
  saveRegisterForm,
}