import React from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import required modules
import { Pagination, Navigation } from "swiper";
import {
  Box,
  Card,
  IconButton
} from '@mui/material'
import LeftArrow from '../assets/leftArrow.png'
import RightArrow from '../assets/rightArrow.png'

const SwiperCourses = (props) => {
  const { StepCourses } = props

  return (
    <Box width='100%' sx={{ display: 'flex', flexDirection: 'inline' }}>
      <Box
        sx={{
          marginTop: {xs: '20%', md: '21%'},
          marginRight: {lg:'15px', xl:'50px'},
        }}
      >
        <IconButton
          sx={{
            display: 'flex',
            background: '#fff',
          }}
          onClick={() => {
            let swiper = document?.querySelectorAll('.swiper')[1].swiper
            return (swiper.slidePrev())
          }}
        >
          <img src={LeftArrow} color='#7f7f7f' alt='left Arrow' />
        </IconButton>
      </Box>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        slidesPerGroup={1}
        loop={true}
        loopFillGroupWithBlank={true} 
        modules={[Pagination]}
        className="mySwiper"
        sx={{ display: 'flex', justifyContent: 'center' }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 30
          },
          900: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            spaceBetween: 30
          },
          1300: {
            slidesPerView: 3,
            slidesPerGroup: 1,
            spaceBetween: 30
          }
        }}
      >
        {
        StepCourses.map((services, index) => {
          return(
            <SwiperSlide key={index}>
              <Card
                sx={{
                  width: {sm: '98%', md: '97%', lg:'369px'},
                  height: {sm: 'fit', lg:'563px'},
                  boxShadow: 4,
                  borderRadius: '12px',
                  marginTop: '10px',
                  marginBottom: {xs: '10px', lg:'50px'},
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              >
              <Box
                  sx={{
                    backgroundColor: '#5D5D5D',
                    borderRadius: '50px',
                    width: '98px',
                    height: '98px',
                    margin: '0 auto',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <Box sx={{ marginTop: '20px' }}>
                    <img src={services.icon} alt='icono venemergencia' width='100%' height='auto' />
                  </Box>
                </Box>
                <Box
                  className='font-family-AvenirNextCondensed'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    fontSize: '20px',
                    color: '#FF0000',
                    paddingTop: '10px',
                    paddingLeft: '40px',
                    paddingRight: '40px'
                  }}>
                  {services.title}
                </Box>
                <Box
                  className='font-family-AvenirNextCondensed'
                  sx={{
                    paddingTop: '5px',
                    paddingRight: '10px',
                    fontSize: '20px',
                    paddingLeft: '40px',
                    fontWeight: 'bolder',
                    color: '#FF0000',
                  }}
                >
                  {services.subtitle}
                </Box>
                <ul>
                  <Box
                    className='font-family-AvenirNextCondensed'
                    sx={{
                      paddingTop: '5px',
                      fontSize: '15px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      color: '#8A8A8A',
                    }}
                  >
                  {
                    services.text.map((event, idx) => {
                      return(
                        <li key={idx}>
                          <Box
                            fontWeight={500}
                            sx={{
                              lineHeight: '28px',
                              fontSize: '15px'
                            }}
                          >
                            {event}
                          </Box>
                        </li>
                      )
                    })
                    }
                  </Box>
                </ul>
              </Card>
            </SwiperSlide>
          )
        })
        }
      </Swiper>
      <Box
        sx={{
          marginTop: {xs: '20%', md: '21%'},
          marginLeft: '15px',
        }}
      >
        <IconButton
          sx={{
            display: 'flex',
            background: '#fff',
          }}
          onClick={() => {
            let swiper = document?.querySelectorAll('.swiper')[1].swiper
            return (swiper.slideNext())
          }}
        >
          <img src={RightArrow} color='#7f7f7f' alt='right arrow' />
        </IconButton>
      </Box>
    </Box>
  )
}

export default SwiperCourses