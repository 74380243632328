import React from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper'
import {
  Box,
  Container
} from '@mui/material'

SwiperCore.use([Navigation, Autoplay])

const Carousel = ({
  data = [{}]
}) => {

  return (
    <Box width='100%' sx={{ display: 'flex', flexDirection: 'inline' }}>
      <Box
        sx={{
          marginTop: {xs: '20%', md: '21%'},
        }}
      >
      </Box>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className='mySwiper'
        loop
        autoplay={{
          delay: 10000,
          disableOnInteraction: false
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 30
          }
        }}
      >
        {
          data.map((services, index) => {
            return (
              <SwiperSlide key={index}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Box
                    sx={{
                      backgroundImage: `url(${services.url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      width: '100%',
                      height: '980px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: {xs: '30px', lg: '50px'},
                        paddingTop: {xs: '80px', lg: '0px'},
                        flexDirection: {xs: 'column', md:'row'},
                        paddingBottom: {xs: '5rem', md: '0', xl: '0'},
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Container maxWidth='xl' />
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
          )})
        }
      </Swiper>
    </Box>
  )
}

export default Carousel
