import step1 from '../../assets/bgHome1.png'
import step2 from '../../assets/bgHome2.png'
import step3 from '../../assets/bgHome3.png'

const StepsContent = [
  {
    url: step1,
  },
  {
    url: step2,
  },
  {
    url: step3,
  }
]

export default StepsContent
