import React from 'react'
import Footer from '../components/Footer'
import {
  Box,
  Container,
  Grid,
} from '@mui/material'
import { format } from 'date-fns'
import LogoFundacion from '../assets/fundacion venemergencia logo.png'
import LogoFundacionConRif from '../assets/LogoFundacionConRif.png'
import Banner3 from '../assets/Banenr3.svg'
import Background from '../assets/background.png'
import RegisterForm from '../helpers/form/RegisterForm'
import Client from '../services/api'
import CustomizedSnackbars from '../components/Alert'
import Carousel from '../components/SwiperCarousel'
import Carousel2 from '../components/SwiperCourses'
import StepContent from '../helpers/content/stepsCarousel'
import StepCourses from '../helpers/content/stepCourses'
import Call from '../assets/llamada.png'
import Email from '../assets/email.png'
import Instagram1 from '../assets/instagram1.png'
import Doctores from '../assets/Paramedicosl 2.png'
import RedBg from '../assets/redBg.png'
import '../styles/Home.css'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const [state, setState] = React.useState ({
    fullname: '',
    available_date: '',
    phone_main: '+58',
    course: '',
    email_main: '',
  })

  const navigate = useNavigate()

  const [timeAlert, setTimeAlert] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [styleAlert, setStyleAlert] = React.useState('')

  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value })
  }

  const handleChangeDate = (props) => (event) => {
    let date = format(new Date(event), 'yyyy-MM-dd')
    setState({ ...state, [props]: 'event Sat Apr 29 2023 00:00:00 GMT-0400 (hora de Venezuela)' })



    // const newDate = new Date(event)
    // const year = newDate.getFullYear().toString()
    // const month = newDate.getMonth().toString()
    // const day = (newDate.getDate()+1).toString()
    // const total = `${day}/${month}/${year}`
  }

  const handleCourse = (prop) => (event) => {
    setState({ ...state, 'info': prop })
  }

  const handleTerms = (prop) => (event) => {
    setState({ ...state, 'terms': !state.terms })
  }

  const onSubmitLogin = async(e) => {
    let data = {
      ...e,
      'available_date': state.available_date
    }
    Client.saveRegisterForm(data)
    .then ((response)=> {
      if(response.data.success === true) {
        navigate("/thankyou")
        setState({
          fullname: '',
          available_date: '',
          phone_main: '+58',
          course: '',
          email_main: '',
        })
        return
      }
    })
    .catch(error => {
      setTimeAlert(true)
      setStyleAlert('error')
      setMessage(error.response.data.available_date[0])
      console.log('error',error)
      setTimeout(() => {
        setTimeAlert(false)
      }, 5000)
    })
    }

  return(
    <>
      <Box>
        <Carousel data={StepContent} />
          <Box>
            <Container maxWidth='xl'>
              <Box
                sx={{
                  fontSize: {xs: '30px', lg: '50px'},
                  paddingTop: {xs: '80px', lg: '150px'},
                  flexDirection: {xs: 'column', md:'row'},
                  paddingBottom: {xs: '5rem', md: '0', xl: '0'},
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: {xs: '-1000px', md: '-1000px'}
                }}
              >
                <Grid
                  container
                  sx={{
                    fontSize: {xs:'50px', md:'65px', xl: '65px'},
                  }}
                >
                  <Grid
                    item
                    lg={3.5}
                    md={8}
                    xs={12}
                    sx={{
                      zIndex: '10'
                    }}
                  >
                    <Box>
                      <Box
                        className='font-family-Airwaves'
                        color='#fff'
                        fontWeight={400}
                        sx={{
                          fontStyle: 'italic',
                          paddingTop: {md: '0', lg: '100px'},
                        }}
                      >
                        DURANTE 12 AÑOS LA FUNDACIÓN VENEMERGENCIA
                      </Box>
                      <Box
                        className='font-family-AvenirNextCondensed'
                        sx={{
                          color: '#fff',
                          fontWeight: 'lighter',
                          fontSize: '38px',
                          width: {xs:'100%', md:'400px', xl: '400px'},
                          lineHeight: '40px',
                          paddingBottom: '30px'
                        }}
                      >
                        ha desarrollado programas educativos que garantizan la adecuada atención de las emergencias médicas en Venezuela
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                  width: {xs: 'full', md: '380px', lg : '380px'},
                  marginLeft: {xs: 'none', md:'-50px', lg: '-200px'},
                  zIndex: '10'
                }}
                >
                  <Box
                    container
                    sx={{
                      justifyContent: 'center',
                      display: 'flex',
                      paddingBottom: '20px',

                      }}
                  >
                    <img src={LogoFundacion} style={{ maxWidth: '335px' }} width='80%' height='auto' alt='Venemergencia' />
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      height: 'auto',
                      fontSize: '16px',
                      borderRadius: '30px',
                      borderTop: '120px solid #FF0000',
                      backgroundColor: '#fff',
                      color: '#000',
                      marginBottom: '30px',
                      boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px'
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: '-90px',
                        textAlign: 'center',
                        color: '#fff',
                        fontSize: '16px',
                        marginLeft: '25px',
                        marginRight: '25px',
                      }}
                    >
                    <Box className='font-family-Airwaves' sx={{ fontSize: '27px', maxWidth: '100%', fontStyle: 'italic' }}>
                      REGÍSTRATE Y CAPACÍTATE CON NOSOTROS
                    </Box>
                    <RegisterForm
                      {...state}
                      handleChange={handleChange}
                      handleChangeDate={handleChangeDate}
                      onSubmitLogin={onSubmitLogin}
                      handleCourse={handleCourse}
                      handleTerms={handleTerms}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
          <Container maxWidth='xl'>
            <Box
              className='font-family-Airwaves'
              sx={{
                textAlign: 'center',
                fontStyle: 'italic',
                fontSize: '45px',
                color: '#FF0000',
                width: '100%',
                paddingTop: '40px',
                paddingBottom: '60px'
              }}
            >
              CONOCE NUESTROS CURSOS
            </Box>
            <Carousel2 StepCourses={StepCourses} />
          </Container>
        </Box>
        <Box
          sx={{
            background: '#fff',
            width: '100%',
            height: 'auto',
            paddingBottom: '100px',
            paddingTop: '40px'
          }}
        >
        </Box>
        <Box
          sx={{
            width: '100%',
            height: {xs:'650px', md:'900px'},
            backgroundImage: `url(${RedBg})`,
            backgroundSize: 'cover'
          }}
        >
          <Container maxWidth='xl'>
          <Box
            sx={{
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'center',
              margin: '0 auto',
              width: {xs:'300px', lg: '466px'}
            }}
          >
            <img src={LogoFundacionConRif} width='100%' alt='logo fundacion' />
          </Box>
          <Box
              className='font-family-Airwaves'
              sx={{
                textAlign: 'center',
                fontStyle: 'italic',
                fontSize: '45px',
                color: '#fff',
                width: '100%',
              }}
            >
              CREEMOS Y HACEMOS
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px'
              }}
            >
              <Box
                sx={{
                  width: {xs: '100%', md:'850px', xl: '850px'},
                  height: {xs: '300px', md: '500px', xl: '500px'},
                }}
              >
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/QcSDOpNuzBw'
                  title='Venemergencia 18 años'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                />
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: {xs:'520px', md:'520px'},
            backgroundColor: '#f7caaf',
            backgroundSize: 'cover'
          }}
        >
          <Container maxWidth='xl'>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                lg={12}
                xl={6}
                sx={{
                  margin: '0 auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Box sx={{ flexDirection: 'column' }}>
                  <Box
                    className='font-family-Airwaves'
                    sx={{
                      color: '#FE0000',
                      fontStyle: 'italic',
                      fontSize: '70px',
                      paddingTop: '120px',
                    }}
                  >
                    ¡Contáctanos!
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '20px'
                    }}
                  >
                    <Box marginRight={1}>
                      <img src={Call} alt='call icon venemergencia' />
                    </Box>
                    <Box color='#6B1111'>
                      0212 822.12.50 / 0212 313.55.50
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '20px'
                    }}
                  >
                    <Box marginRight={1}>
                      <img src={Email} alt='Email icon venemergencia' />
                    </Box>
                    <Box color='#6B1111'>
                      Fundavenemergencia@grupov.com.ve
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '20px'
                    }}
                  >
                    <Box marginRight={1}>
                      <img src={Instagram1} alt='Instagram icon venemergencia' />
                    </Box>
                    <Box color='#6B1111'>
                      @fundaciónvenemergencia
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: {xs:'none', lg: 'none', xl: 'block'}
                }}
              >
                <img src={Doctores} alt='3 Doctores venemergencia' width='100%' height='auto' />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Footer />
      {
        timeAlert === true &&
        <CustomizedSnackbars
          message={message}
          styleAlert={styleAlert}
        />
      }
    </>
  )
}

export default Home
