import React from 'react'

import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: Airwaves;
        src: url('../theme/fonts/Airwaves-Regular.eot') format('embedded-opentype')
        , url('../theme/fonts/Airwaves-Regular.ttf') format('truetype')
        , url('../theme/fonts/Airwaves-Regular.woff') format('woff')
        , url('../theme/fonts/Airwaves-Regular.woff2') format('woff2');
      }
      /* latin */
      @font-face {
        font-family: Avenir;
        src: url('../theme/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
      }
      /* latin */
      @font-face {
        font-family: AvenirCondensed;
        src: url('../theme/fonts/AvenirNextLTPro-Condensed.ttf') format('truetype');
      }
    `}
  />
)

export default Fonts
