import BebeIcon from '../../assets/bebe.png'
import ChildIcon from '../../assets/child.png'
import GrandMa from '../../assets/grandMa.png'
import GrandPa from '../../assets/grandPa.png'
import Runing from '../../assets/runing.png'
import Camping from '../../assets/camping.png'

const StepsCourses = [
  {
    icon: BebeIcon,
    title: 'Proveedor de Auxilio Médico Inmediato (PAMI)',
    subtitle: 'PEDIÁTRICO BÁSICO (4 horas)',
    text: ['Evaluación inicial de un paciente pediátrico',
      'Atención de lesiones comunes en pacientes pediátricos',
      'Manejo de la fiebre en paciente pediátrico',
      'Soporte Básico de Vida(Reanimación Cardio-Pulmonar Pediátrica)',
      'Obstrucción de la vía aérea pediátrica, asfixia',
      'y atragantamiento'
    ]
  },
  {
    icon: ChildIcon,
    title: 'Proveedor de Auxilio Médico Inmediato (PAMI)',
    subtitle: 'PEDIÁTRICO AVANZADO (8 horas)',
    text: ['Evaluación inicial de un paciente pediátrico',
      'Atención de lesiones comunes en pacientes pediátricos',
      'Manejo de patologías comunes en pacientes pediátricos',
      'Particularidades en el examen físico pediátrico',
      'Soporte Básico de Vida (Reanimación Cardio-Pulmonar Pediátrica)',
      'Obstrucción de la vía aérea pediátrica, asfixia y atragantamiento',
      'Accidentes en niños, manejo y su prevención'
    ]
  },
  {
    icon: GrandMa,
    title: 'Proveedor de Auxilio Médico Inmediato (PAMI)',
    subtitle: 'ADULTOS MAYORES BÁSICO (4 horas)',
    text: ['Evaluación inicial del adulto mayor',
      'Atención de lesiones comunes en pacientes adultos mayores',
      'Abordaje integral del adulto mayor y evaluación neurológica/mental',
      'Obstrucción de la vía aérea, asfixia y atragantamiento',
      'Soporte Básico de Vida (Reanimación Cardio-Pulmonar)',
    ]
  },
  {
    icon: GrandPa,
    title: 'Proveedor de Auxilio Médico Inmediato(PAMI)',
    subtitle: 'PADULTOS MAYORES AVANZADO (8 horas)',
    text: ['Evaluación inicial del adulto mayor',
      'Atención de lesiones comunes en pacientes adultos mayores',
      'Obstrucción de la vía aérea, asfixia y atragantamiento',
      'Particularidades del examen físico en el adulto mayor',
      'Soporte Básico de Vida (Reanimación Cardio-Pulmonar)',
      'Manejo de patologías comunes en adultos mayores',
      'Abordaje neurológico del adulto mayor y patologías asociadas',
      'Botiquín de primeros auxilios con adultos mayores'
    ]
  },  {
    icon: Runing,
    title: 'Proveedor de Auxilio Médico Inmediato(PAMI)',
    subtitle: 'DEPORTES AL AIRE LIBRE Y CAMPING BÁSICO (4 horas)',
    text: ['Evaluación inicial de un paciente o lesionado',
      'Atención de lesiones comunes en actividades al aire libre',
      'Atención de patologías asociadas a temperaturas extremas',
      'Soporte básico de vida y Obstrucción de vía aérea',
    ]
  },
  {
    icon: Camping,
    title: 'Proveedor de Auxilio Médico Inmediato(PAMI)',
    subtitle: 'DEPORTES AL AIRE LIBRE Y CAMPING AVANZADO (8 horas)',
    text: ['Evaluación inicial de un paciente o lesionado',
      'Atención de lesiones comunes en actividades al aire libre',
      'Atención de patologías asociadas a temperaturas extremas',
      'Atención de patologías asociadas a deportes al aire libre',
      'Abordaje inicial de patologías en lugares aislados',
      'Soporte básico de vida y Obstrucción de vía aérea',
      'Botiquín de primeros auxilios para deportes al aire libre'
    ]
  }

  // {
  //   url: step2,
  // },
  // {
  //   url: step3,
  // }
]

export default StepsCourses
