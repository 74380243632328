import * as React from 'react'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const CustomizedSnackbars = (props) => {
  const {message, styleAlert} = props

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={true}
        //autoHideDuration={6000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      >
        <Alert severity={styleAlert} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">{message}</Alert>
      <Alert severity="success">{message}</Alert> */}
    </Stack>
  );
}

export default CustomizedSnackbars
