import React from 'react'

import Logo from '../../src/assets/logo-vertical.svg'
import Iso from '../../src/assets/logo-iso.png'
import {
  Container,
  Box,
  Divider,
  Button,
  Link,
  ButtonGroup,
  Avatar,
} from '@mui/material'

import { directionContent } from '../helpers/content/directionContent'
import { linksToShow } from '../helpers/content/linksToShow'
import { socialLinksContent } from '../helpers/content/socialLinksContent'
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from 'react-icons/fa'

const Footer = () => {

  return (
    <Box
      backgroundColor='#000'
      color='#fff'
      sx={{
        paddingTop:{xs:'5rem', md:'5rem', xl: '5rem'},
        width: '100%',
      }}
    >
      <Container maxWidth='xl'>
        <Box sx={{ flexDirection: {xs: 'column', md: 'column', lg: 'row'}, display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ marginRight: '50px', width: { xs: '100%', md: '100%', lg: 'auto' } }}>
              <Box
                sx={{
                  display: {xs: 'flex'},
                  justifyContent: {xs: 'center', lg: 'start'},
                }}
              >
                <img src={Logo} alt='logo' />
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                  fontWeight: 'bold',
                  textAlign: {xs: 'center', md: 'center', lg: 'left'},
                  width: {xs: '100%', md: '100%', lg:'200px'}
                }}
              >
                Dedicados a mejorar la calidad de vida de los venezolanos
              </Box>
              <Button
                variant='contained'
                color='error'
                href='https://venemergencia.com/contacto'
                sx={{
                  width: {xs: '100%', md: '100%', lg: '150px'},
                  backgroundColor: '#ff0000',
                  borderRadius: '30px',
                  marginTop: '40px',
                  fontSize: '10px'
                }}
              >
                Contáctanos
              </Button>
            </Box>
          <Box
            sx={{
              marginRight: {xs: '0', md: '150px', lg: '150px'},
              textAlign: {xs: 'center'},
              marginTop: {xs: '30px', md: '30px', lg: '0'},
              width: {xs: '100%', md: '100%', lg: 'auto'}
            }}
          >
            {linksToShow[0].map((linkData, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    flexDirection: 'column',
                    paddingBottom: '30px',
                    width: '100%'
                  }}
                >
                  <Link href={linkData.link} sx={{ textDecoration: 'none', color: '#fff' }}>
                    {linkData.label}
                  </Link>
                </Box>
              )
            })}
          </Box>
          <Box
            sx={{
              marginRight: {xs: '0', md: '100px', lg: '100px'},
              textAlign: {xs: 'center'},
              width: {xs: '100%', md: '100%', lg: 'auto'}
            }}
          >
            {linksToShow[1].map((linkData, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    flexDirection: 'column',
                    paddingBottom: '30px'
                  }}
                >
                  <Link href={linkData.link} sx={{ textDecoration: 'none', color: '#fff' }}>
                    {linkData.label}
                  </Link>
                </Box>
              )
            })}
          </Box>
          <Divider
            orientation='vertical'
            flexItem
            color='#fff'
            sx={{
              height: {xs: 'none', md: 'none', lg: '230px'},
            }}
          />
          <Box
            sx={{
              marginTop: {xs: '10px', md: '0'},
              marginLeft: {xs: '0', md: '0', lg: '100px'},
            }}
          >
            {directionContent.map((line, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    fontWeight: index === 0 ? 'bold' : 'normal',
                    paddingBottom: '30px',
                    textAlign: {xs: 'center', md: 'center', lg: 'left'},
                  }}
                >
                  {line}
                </Box>
              )
            })}
          </Box>
        </Box>
        <Box
          pt='10px'
          align='center'
          sx={{
            flexDirection: {xs: 'column', md: 'column', lg: 'row'},
            display: 'flex',
            justifyContent: {xs: 'none', md: 'space-between', lg: 'space-between'},
            textAlign: {xs: 'center'},
            paddingBottom: {xs: '80px'},
            width: {xs: '100%', md: '100%', lg: '91%'}
          }}
        >
          <Box
            sx={{
              marginTop: {xs: '0', md: '20px', lg: '20px'},
              marginBottom: {xs: '20px'},
              marginLeft: {xs: '0', md: '0', lg: '150px'}
            }}
          >&copy;&nbsp;{new Date().getFullYear()}&nbsp;Venemergencia</Box>
          
          <Box sx={{ textAlign: 'center', flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{
              fontWeight: 'bold',
              marginBottom: {xs: '10px', md: '0'},
              marginTop: '10px',
              }}
            >
              Síguenos en:
            </Box>
            <ButtonGroup>
              <Avatar
                as={Link}
                href={socialLinksContent.facebook}
                target='_blank'
                rel='noreferrer'
                sx={{
                  border: 'none',
                  size: 'lg',
                  marginLeft: '10px',
                  backgroundColor: '#e32726',
                }}
              >
                <FaFacebookF color='#000' />
              </Avatar>
              <Avatar
                as={Link}
                href={socialLinksContent.instagram}
                target='_blank'
                rel='noreferrer'
                sx={{
                  border: 'none',
                  size: 'lg',
                  marginLeft: '10px',
                  backgroundColor: '#e32726',
                }}
              >
                <FaInstagram color='#000' />
              </Avatar>
              <Avatar
                as={Link}
                href={socialLinksContent.twitter}
                target='_blank'
                rel='noreferrer'
                sx={{
                  border: 'none',
                  size: 'lg',
                  marginLeft: '10px',
                  backgroundColor: '#e32726',
                }}
              >
                <FaTwitter color='#000' />
              </Avatar>
              <Avatar
                as={Link}
                href={socialLinksContent.linkedin}
                target='_blank'
                rel='noreferrer'
                sx={{
                  border: 'none',
                  size: 'lg',
                  marginLeft: '10px',
                  backgroundColor: '#e32726',
                }}
              >
                <FaLinkedinIn color='#000' />
              </Avatar>
            </ButtonGroup>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
