import axios from 'axios'

const serverUrl = process.env.REACT_APP_PUBLIC

const API = (options) => {
  return axios.create({
    baseURL: `${serverUrl}/api/`,
    headers: { ...options}
  })
}

export default API
