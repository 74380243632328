export const linksToShow = [
  [
    {label: 'Home', link: 'https://venemergencia.com/'}, 
    {label: 'Quiénes Somos', link: 'https://venemergencia.com/quienes-somos'},
    {label: 'Servicios', link: 'https://venemergencia.com/servicios'},
    {label: 'Planes', link: 'https://venemergencia.com/planes'},
    {label: 'Fundación', link: 'https://venemergencia.com/fundacion'},
  ],
  [
    {label: 'Contacto', link: 'https://venemergencia.com/contacto'},
    {label: 'FAQ', link: 'https://venemergencia.com/preguntas-frecuentes'},
    {label: 'Iniciar sesión', link: 'https://afiliaciones.venedigital.com/iniciar-sesion/'},
  ]
]
