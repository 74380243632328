import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import LogoFundacion from '../assets/fundacion venemergencia logo.png'
import Carousel from '../components/SwiperCarousel'
import StepContent from '../helpers/content/stepsCarousel'
import { useNavigate } from 'react-router-dom'

const Thankyou = () => {
  const navigate = useNavigate()
  return (
    <>
      <Carousel data={StepContent} />
      <Box
        sx={{
          fontSize: { xs: '30px', lg: '50px' },
          display: 'flex',
          justifyContent: 'center',
          marginTop: { xs: '-800px', md: '-800px' },
          width: '100%'
        }}
      >
        <Box
          sx={{
            width: { xs: '60%', md: '100%' },
            zIndex: '10',
            display: 'inherit',
            justifyContent: 'inherit',
            flexDirection: 'column'
          }}
        >
          <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              sx={{
                color: '#fff',
                backgroundColor: '#000000',
                borderRadius: '50%',
                padding: '20px',
                fontSize: '1rem',
                display: 'flex',
                marginRight: { xs: '0', md: '1rem' },
                ':hover': {
                  backgroundColor: 'rgb(173 173 173 / 25%)'
                }
              }}
              onClick={() => navigate('/')}
            >
              X
            </Button>
          </Box>
          <Box
            container
            sx={{
              justifyContent: 'center',
              display: 'flex',
              paddingBottom: '20px'
            }}
          >
            <img src={LogoFundacion} style={{ maxWidth: '335px' }} width='80%' height='auto' alt='Venemergencia' />
          </Box>
          <Box
            sx={{
              display: 'inherit',
              flexDirection: 'inherit',
              textAlign: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              component='h1'
              sx={{
                fontSize: { xs: '1.5rem', lg: '2.2rem' },
                fontFamily: 'Airwaves',
                width: '100%',
                maxWidth: '500px',
                fontStyle: 'italic',
                color: '#fff',
                backgroundColor: '#FF0000',
                paddingY: '3rem',
                paddingX: '5rem',
                display: 'inherit',
                justifyContent: 'center',
                borderRadius: '3rem 3rem 0 0'
              }}
            >
              REGÍSTRATE Y CAPACÍTATE CON NOSOTROS
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: '1.5rem', lg: '2.2rem' },
                fontFamily: 'Airwaves',
                width: '100%',
                maxWidth: '500px',
                fontStyle: 'italic',
                backgroundColor: '#fff',
                paddingY: '3rem',
                paddingX: '5rem',
                display: 'inherit',
                justifyContent: 'center',
                borderRadius: '0 0 3rem 3rem'
              }}
            >
              Gracias por registrarte
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Thankyou
